<style lang="less" scoped>
.Preview-Exam-Module {
  .stu-module-header {
    padding: 20px;
    background: #fff;
    box-shadow: @shadow;
    margin-bottom: 15px;
  }
  .content {
    .red {
      color: #ff0000;
    }
    .green {
      color: #009999;
    }
    .slot_total {
      display: inline-block;
      margin-right: 12px;
      font-weight: bold;
    }
    .items {
      border: 1px solid @borderBottomColor;
      margin-bottom: 20px;
      .header {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        border-bottom: 1px solid @borderBottomColor;
        .h_left {
          span {
            font-weight: bold;
            font-size: 16px;
          }
          span:first-child {
            display: inline-block;
            margin-right: 10px;
          }
          span:last-child {
            display: inline-flex;
            .h_left_title {
              font-weight: bold;
            }
          }
        }
        .h_right {
          font-weight: bold;
          font-size: 16px;
        }
      }
      @media screen and (max-width: 1200px) {
        .header {
          flex-flow: column;
        }
      }
      .list_ul {
        padding: 20px;
        padding-bottom: 0;
        li {
          padding: 10px 0 10px 12px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -2px;
            height: 4px;
            width: 4px;
            border-radius: 50%;
            background: #000;
          }
          span {
            font-size: 16px;
          }
        }
      }
      .referAnswer {
        background-color: #f9f9f9;
        padding: 20px;
        border: 1px dashed #d8d8d8;
        overflow: hidden;
        color: #999;
        margin: 0 20px;
        margin-top: 20px;
        font-size: 16px;
      }
      .parse {
        padding: 20px 40px;
        p {
          font-size: 16px;
        }
        .parse_label {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="Preview-Exam-Module">
    <div class="stu-module-header">
      <div class="stu-module-title">试卷预览</div>
      <el-button @click="back" type="primary">返回</el-button>
    </div>
    <el-card>
      <div slot="header">
        试卷要求：<span>{{ datas.desc }}</span>
      </div>
      <div class="content">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            v-for="(item, index) in data"
            :key="index"
            :name="index"
          >
            <template slot="title">
              <span class="slot_total">{{ item.questionName }}</span>
            </template>

            <div class="items" v-for="(c, i) in item.questionList" :key="i">
              <div class="header">
                <div class="h_left">
                  <div>
                    {{ i + 1 }}、<span v-html="c.title"></span>
                    <ul
                      class="list_ul"
                      v-for="(q, qindex) in c.options"
                      :key="qindex"
                    >
                      <li>
                        <span>{{ q.itemKey }}</span>
                        <span>{{ q.itemVal }}</span>
                      </li>
                    </ul>
                    <div class="parse">
                      <span class="green">参考答案：</span>
                      <span v-html="c.questionKey"></span>
                    </div>
                    <div class="parse">
                      <span class="green">问题解析：</span>
                      <span v-html="c.questionAnalyze">ssc</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "preview-exam-module",

  activated() {
    this.init();
  },
  data() {
    return {
      data: [],
      datas: {},
      active: null,
    };
  },
  computed: {
    activeNames: {
      get() {
        // 展开全部
        return this.active || this.data.map((m, i) => i);
      },
      set(v) {
        this.active = v;
      },
    },
  },
  methods: {
    init() {
      const id = this.$route.query.id;
      this.loading = true;
      this.$axios_supermallData
        .get("/hnjxjy-core/TeaCourseStudentExam/examPreview?id=" + id)
        .then((res) => {
          this.loading = false;
          if (res.data.code == 2000) {
            this.data = res.data.data.bigQuestions;
            this.datas = res.data.data;
            for (let k = 0; k < this.data.length; k++) {
              for (let j = 0; j < this.data[k].questionList.length; j++) {
                this.data[k].questionList[j].questionAnalyze = this.data[
                  k
                ].questionList[j].questionAnalyze
                  .toString()
                  .replace(/<[^>]+>/g, "");
                this.data[k].questionList[j].questionKey = this.data[
                  k
                ].questionList[j].questionKey
                  .toString()
                  .replace(/<[^>]+>/g, "");
                this.data[k].questionList[j].title = this.data[k].questionList[
                  j
                ].title
                  .toString()
                  .replace(/<[^>]+>/g, "");
              }
            }
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push("/teacher/t/exam/online");
    },
  },
};
</script>
